.brand-widget {
  text-align: center;
  padding: 60px;
}

.brand-widget h1 {
  font-family: 'Termina', sans-serif;
  font-weight: 500;
}

.slick-next {
  right: -25px !important;
}
.brand-container {
  border: 3px solid #000000;
  margin-bottom: 80px;
  padding: 20px;
}

.arrow-icon-btn {
  border: none;
  padding: 10px;
  background: #b8b8b8;
  color: #ffffff;
  cursor: pointer;
  transition: background 0.3s ease; /* Adds smooth transition */
}

.arrow-icon-btn:hover {
  background: #000000; /* Changes background to black on hover */
}

.brand-logo {
  width: 200px;
  margin: auto !important;
}

.slick-arrow {
  display: none !important;
}
