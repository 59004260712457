.bg-dark-mid-quote {
  background: #000000;
  padding-bottom: 20px;
  padding-top: 35px;
}

.bg-dark-mid-quote h2 {
  color: #ddfe34;
  font-family: "Termina", sans-serif;
  font-weight: 500;
}

.bg-dark-mid-quote h5 {
  font-family: "Termina", sans-serif;
  font-weight: 500;
}

.bg-dark-mid-quote p {
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.mod-quote-contact {
  color: #fff;
}

.green-quote-icon {
  color: #ddfe34;
}

@media (max-width: 990px) {
  .title-mid-quote{
    text-align: center;
  }
}