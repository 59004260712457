.row-green-wid h4 {
  font-family: "TT Commons", sans-serif;
  font-weight: 600;
}

.row-green-wid p {
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.row-green-wid {
  background: linear-gradient(to left, #4bfe2d, #ddfe34);
  align-items: center;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: -40px !important;
}

.learn-m-green {
  text-align: center;
  color: #ddfe34;
  background: #000000;
  padding: 8px;
  width: 140px;
  font-weight: 600;
}

.custome-button-green-wd {
  transform: translate(0, 10%);
  cursor: pointer;
}

.custom-icon-sm-green-wd {
  color: #000000;
  transform: translate(50%, -40%);
}

.custom-logo-black {
  width: 55px;
  height: 55px;
  margin-right: 5px;
}

@media (max-width: 767px) {
  .custom-icon-col-green-wd {
    text-align: center !important;
    margin-top: 30px !important;
  }
}

@media (min-width: 768px) {
  .custome-button-green-wd {
    justify-content: end;
  }
}
