* {
  line-height: 2;
}

.footer-top {
  background-color: #000000;
}

footer h6 {
  margin-bottom: 1rem;
  color: #ffff;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

footer p {
  margin: 0;
  color: #aeaaaa;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.footer-top img {
  height: 35px;
  margin-bottom: 10px;
}

.footer-top.py-4 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.footer-middle {
  background: linear-gradient(to left, #4bfe2d, #ddfe34);
  align-items: center;
}

.footer-middle h2 {
  font-family: "Termina", sans-serif;
  font-weight: 500;
}

.footer-middle p {
  color: #ddfe34;
  background: #000000;
  padding: 10px;
  width: 120px;
  text-align: center;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.mb-10 {
  display: flex;
  align-items: center;
  height: 100px;
}

.mb-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 0.5rem !important;
}

.footer-bottom {
  background-color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer-bottom p {
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.footer-bottom .mb-4 {
  margin-bottom: 0px !important;
}

.custom-icon-footer {
  color: #000000;
  background: #ffff;
  padding: 2px;
}

.lets-talk-btn {
  cursor: pointer;
}

.footer-b-link {
  cursor: pointer;
}

.footer-content-left {
  padding-right: 50px !important;
}

.footer-content-right {
  padding-left: 50px !important;
}

.footer-content-mid {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.footer-bottom-black-left {
  text-align: start;
}

.footer-bottom-black-right {
  text-align: center;
}

.footer-bottom-black-middle {
  text-align: end;
}

@media (max-width: 767px) {
  .footer-middle h2 {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
  }

  .main-logo-footer {
    text-align: center;
    margin-bottom: 30px;
  }

  .footer-content-left {
    padding: 10px !important;
  }

  .footer-content-right {
    padding: 10px !important;
  }

  .footer-content-mid {
    padding: 10px !important;
  }
}

@media (min-width: 768px) {
  .green-box-black-btn {
    justify-content: end;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .footer-top img {
    height: 20px;
  }
}

@media (max-width: 990px) {
  .footer-bottom-black-left,
  .footer-bottom-black-right,
  .footer-bottom-black-middle {
    text-align: center;
  }
}
