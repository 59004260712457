.service-model-main {
  margin-top: 100px;
  margin-bottom: 100px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .service-model-main {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
