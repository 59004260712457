.machine-model-content {
  padding: 40px !important;
}

.machine-model-content h3 {
  font-family: "Termina", sans-serif;
  font-weight: 500;
}

.machine-model-content p {
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.machine-model-container {
  border: 3px solid #000;
}

.image-container-machine {
  overflow: hidden;
}

.img-cropped-machine {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .image-container-machine {
    overflow: hidden;
    height: 30vh;
  }
}

@media (min-width: 1600px){
  .image-container-machine {
    overflow: hidden;
    height: 39vh;
  }
}

@media (min-height: 1200px) and (max-height: 1499px) {
  .image-container-machine {
    overflow: hidden;
    height: 30vh;
  }
}

@media (min-height: 1500px) and (max-height: 1600px) {
  .image-container-machine {
    overflow: hidden;
    height: 28vh;
  }
}

@media (min-height: 1600px) {
  .image-container-machine {
    overflow: hidden;
    height: 28vh;
  }
}



