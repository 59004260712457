.bg-mission-about-model {
  background: #000000;
}

.mission-about-model-content {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.bg-dark-mission-about-row {
  padding-top: 20px;
}

.bg-dark-mission-about-row h1 {
  color: #ddfe34;
  margin-top: -10px;
  font-family: "Termina", sans-serif;
  font-weight: 500;
}

.bg-dark-mission-about-row p {
  color: #b8b8b8;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
  padding-bottom:40px ;
  padding-top:40px ;
}

.active-prime-title {
  color: #ddfe34 !important;
}

.image-container-about {
  height: 350px;
  overflow: hidden;
}

.img-cropped {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

@media (max-width: 767px) {
  .mission-about-model-container {
    overflow: hidden;
    text-align: center;
  }

  .mission-about-model-container p {
    padding: 10px;
  }
}
