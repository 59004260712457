.bg-mission-values-model {
  background: #000000;
}

.mission-values-model-content {
  padding: 6rem 8rem 6rem 8rem !important;
}

.bg-dark-mission-values-row h4 {
  color: #fff;
  margin-top: -10px;
  font-family: "Termina", sans-serif;
  font-weight: 500;
}

.bg-dark-mission-values-row p {
  margin-top: 10px;
  color: #b8b8b8;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
}

.image-container-values {
  height: 400px;
  overflow: hidden;
}

.img-cropped-values {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

@media (max-width: 767px) {
  .mission-values-model-container {
    overflow: hidden;
    text-align: center;
  }
  .image-container-values {
    overflow: hidden;
    order: 2;
  }

  .mission-values-model-content {
    order: 1;
  }
}

@media (max-width: 1024px) {
  .mission-values-model-container {
    overflow: hidden;
    text-align: center;
  }
  .image-container-values {
    overflow: hidden;
  }
  .mission-values-model-content {
    padding: 2rem !important;
  }
}
