.bg-mission-val-model {
  background: #000000;
}

.mission-val-model-content {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.bg-dark-mission-val-row {
  padding-top: 20px;
}

.bg-dark-mission-val-row h1 {
  color: #ddfe34;
  margin-top: -10px;
  font-family: 'Termina', sans-serif;
  font-weight: 500;
}

@media (max-width: 767px) {
  .mission-val-model-container {
    overflow: hidden;
    text-align: center;
  }
}
