.left-box,
.mid-box,
.right-box {
  padding: 20px;
  background-color: #000000;
  transition: transform 0.3s;
  text-align: center;
  gap: 0px;
  border-top: 15px solid #ffff;
  border-bottom: 15px solid #ffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.left-box {
  border-left: 15px solid #ffff;
  border-right: 7.5px solid #ffff;

  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.938),
      rgba(0, 0, 0, 0.447)
    ),
    url("../../../assets/Images/Home/escavator.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 100vh;
}

.right-box {
  border-left: 7.5px solid #ffff;
  border-right: 15px solid #ffff;

  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.938),
      rgba(0, 0, 0, 0.447)
    ),
    url("../../../assets/Images/Home/quipments.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 100vh;
}

.mid-box {
  border-left: 7.5px solid #ffff;
  border-right: 7.5px solid #ffff;

  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.938),
      rgba(0, 0, 0, 0.447)
    ),
    url("../../../assets/Images/Home/earth.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 100vh;
}

h4 {
  margin-top: 15px;
}

p {
  margin-bottom: 15px;
}

.box-component .mb-4 {
  padding: 0px !important;
}

.box-component h4 {
  color: #ffff;
  font-weight: 600;
  font-family: "TT Commons", sans-serif;
}

.box-component p {
  color: #dbdbdb;
  font-family: "TT Commons", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.custom-icon {
  color: #ddfe34;
  margin: 50px;

  text-align: center;

  margin-bottom: 20px;
}

.get-q {
  background: #ddfe34;
  color: #000000 !important;
  font-weight: 600 !important;
  padding: 8px 25px;
  cursor: pointer;
  margin-top: auto;
  background: #ddfe34;
  margin-bottom: 15px !important;
}

@media (max-width: 767px) {
  .mid-box {
    border: 10px solid #ffff !important;
    padding: 20px;
    background-color: #000000;
    transition: transform 0.3s;
    text-align: center;
    gap: 0px;
  }

  .box-component {
    margin-top: 10px;
  }

  .left-box {
    border-top: 15px solid #ffff;
    border-bottom: 15px solid #ffff;
    border-left: 15px solid #ffff;
    border-right: 15px solid #ffff;
  }

  .right-box {
    border-top: 15px solid #ffff;
    border-bottom: 15px solid #ffff;
    border-left: 15px solid #ffff;
    border-right: 15px solid #ffff;
  }

  .mid-box {
    border-top: 15px solid #ffff;
    border-bottom: 15px solid #ffff;
    border-left: 15px solid #ffff !important;
    border-right: 15px solid #ffff !important;
  }
}

@media (min-width: 760px) {
  .box-component {
    margin-top: -100px !important;
  }
}

@media (min-width: 1200px) {
  .get-q {
    /* margin: 0px 110px 0px 110px; */
  }
  .box-component {
    margin-top: -140px !important;
  }
}
