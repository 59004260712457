.sm-box{
    background: #000000;
}

.sm-box h4{
    color: #ffffff;
    font-family: 'TT Commons', sans-serif;
    font-weight: 600;
}

.sm-box p{
    color: #b8b8b8;
    font-family: 'TT Commons', sans-serif;
    font-size: 20px;
    font-weight: 500;
}

.custom-icon-sm{
    color: #ddfe34;
}


@media (min-width: 760px) {

    .sm-box-mid{
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
  }