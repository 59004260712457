.about-model-content h3 {
  font-family: "Termina", sans-serif;
  font-weight: 500;
}

.about-model-content p {
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

.about-model-container {
  overflow: hidden;
}

.about-model-main {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  .about-model-main {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .about-model-content {
    text-align: center;
  }
}
