.background-section-quote {
  position: relative;
  width: 100%;
  height: 90vh;
  background: #000000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  margin-top: -60px;
}

.background-section-quote .content {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 1 !important;
}

.background-section-quote .content p {
  line-height: 1;
}

.line-three-quote {
  font-size: 60px;
  font-weight: 600;
  color: #ddfe34;
  font-family: "Termina", sans-serif;
  font-weight: 600;
}

.para-quote {
  color: #ffff;
  margin-top: 120px;
  line-height: 2 !important;
  margin-top: 40px;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
  width: 90%;
}

.para-quote-container {
  padding-left: 0px !important;
}

@media (max-width: 767px) {
  .background-section-quote {
    background-position: top;
    height: 30vh;
    margin-top: 0px;
  }

  .line-one-quote {
    font-size: 15px;
  }

  .line-three-quote {
    font-size: 35px;
  }

  .para-quote {
    display: none;
  }

  .background-section-quote .content {
    position: absolute;
    top: 43% !important;
    transform: translate(0, -70%) !important;
    line-height: 1 !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .background-section-quote {
    background-position: top;
    height: 35vh;
    margin-top: 0px;
  }

  .background-section-quote .content {
    position: absolute;
    top: 49%;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }

  .para-quote {
    margin-top: 20px;
    font-size: 12px;
  }

  .line-one-quote {
    font-size: 30px;
    margin-top: 30px;
  }

  .line-three-quote {
    font-size: 30px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .background-section-quote {
    margin-top: -120px;
    height: 63vh;
  }

  .line-one-quote {
    margin-top: 100px;
  }

  .para-quote {
    margin-top: 100px !important;
  }

  .background-section-quote .content {
    position: absolute;
    top: 55%;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }

  .line-three-quote {
    width: 50%;
    line-height: 1.2 !important;
  }
}

@media (min-width: 1600px) {
  .background-section-quote {
    height: 54vh;
  }

  .background-section-quote .content {
    position: absolute;
    top: 49%;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }
}

@media (min-height: 700px) and (max-height: 999px) {
  .line-three-quote {
    margin-top: 120px;
  }

  .para-quote {
    margin-top: 10px !important;
    width: 80%;
  }
}