.background-section-earth {
  position: relative;
  width: 100%;
  height: 105vh;
  background-image: linear-gradient(
      to right,
      rgb(0, 0, 0),
      rgba(11, 8, 8, 0.501)
    ),
    url("../../../assets/Images/EarthworksQuantites/Hero.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  margin-top: -60px;
}

.background-section-earth .content {
  position: absolute;
  top: 57%;
  transform: translate(0, -47%);
  line-height: 1 !important;
}

.background-section-earth .content p {
  line-height: 1;
}

.line-one-earth {
  font-family: "Termina", sans-serif;
  font-size: 40px;
  font-weight: 500;
  color: #ffff;
}

.line-three-earth {
  font-family: "Termina", sans-serif;
  font-weight: 600;
  font-size: 60px;
  color: #ddfe34;
}

.para-earth {
  width: 90%;
  color: #ffff;
  margin-top: 120px;
  line-height: 2 !important;
  margin-top: 40px;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-top: 120px;
}

.price-check-btn {
  width: 180px;
  color: #000000;
  background: #ddfe34;
  padding: 20px;
  margin-top: 25px;
  text-align: center;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.para-earth-container {
  padding-left: 0px !important;
}

@media (max-width: 767px) {
  .background-section-earth {
    background-position: top;
    height: 30vh;
    margin-top: 0px;
  }

  .line-one-earth {
    font-size: 15px;
  }

  .line-three-earth {
    font-size: 15px;
  }

  .para-earth {
    display: none;
  }

  .background-section-earth .content {
    position: absolute;
    top: 62% !important;
    transform: translate(0, -70%) !important;
    line-height: 1 !important;
  }

  .price-check-btn {
    width: 150px;
    padding: 10px;
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .background-section-earth {
    background-position: top;
    height: 45vh;
    margin-top: 0px;
  }

  .background-section-earth .content {
    position: absolute;
    top: 49% !important;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }

  .para-earth {
    margin-top: 20px;
    font-size: 12px;
  }

  .line-one-earth {
    font-size: 30px;
    margin-top: 30px;
  }

  .line-three-earth {
    font-size: 30px;
  }

  .price-check-btn {
    margin-top: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .background-section-earth {
    margin-top: -120px;
    height: 77vh;
  }

  .line-one-earth {
    margin-top: 100px;
  }

  .background-section-earth .content {
    position: absolute;
    top: 50% !important;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }
}

@media (min-width: 1600px) {
  .background-section-earth {
    height: 80vh;
  }

  .background-section-earth .content {
    position: absolute;
    top: 50% !important;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }
}
