.earth-model-content{
    padding: 30px !important;
}

.earth-model-container{
    border: 3px solid #000;
}

.bottom-margin-earth-model{
   margin-bottom: 100px;
}
