.navbarquote {
  background-color: #ccff00 !important;
  border-radius: 0;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 14px 15px !important;
  font-weight: 700 !important;
}

.active-getquote {
  background-color: #000000 !important;
  border-radius: 0;
  color: rgb(255, 255, 255) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 14px 14px 12px 15px !important;
  margin-right: 0.5px;
  font-weight: 700 !important;
}

.custom-navbar {
  min-height: 60px;
  background: #ffff !important;
  font-weight: 600;
  font-family: "TT Commons", sans-serif;
}

.nav-link::after {
  content: "•";
  margin-left: 10px;
  color: #000000;
}

.nav-link:last-child::after {
  content: "";
}

.custom-navbar .nav-link {
  padding: 10px 15px;
}

.nav-logo img {
  height: 40px;
  cursor: pointer;
}

.custom-navbar .nav-link.active {
  font-weight: 600;
  color: #000000 !important;
}

.navbar-collapse {
  transition: max-height 0.5s ease-out; 
}

.navbar-collapse.show {
  transition: max-height 0.5s ease-in; 
}


@media (max-width: 400px) {
  .nav-logo img {
    height: 30px;
  }
}

@media (max-width: 1023px) {
  .custom-toggle {
    margin: 5px;
  }
  .nav-link::after {
    content: "";
    margin-left: 10px;
    color: #000000;
  }
  .navbarquote {
    margin-bottom:15px ;
  }

  .active-getquote{
    margin-bottom:15px ;
  }
}

@media (min-width: 1000px) and (max-width: 1400px) {
  .navbarquote {
    padding: 25px 15px !important;
  }

  .active-getquote {
    padding: 25px 15px !important;
  }
}
