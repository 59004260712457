.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

@media (min-width: 1600px) {
  .custom-container {
    max-width: 1600px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}


@media (min-width: 1100px) {
  .section-margin-common {
    padding-left: 150px !important;
    padding-right: 150px !important;
  }
}

@media (max-width: 767px) {
  .over-flow{
    overflow: hidden;
  }
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Define the Termina font family */
@font-face {
  font-family: "Termina";
  src: url("../public/fonts/termina/TerminaTest-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Termina";
  src: url("../public/fonts/termina/TerminaTest-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "TT Commons";
  src: url("../public/fonts/TTCommons/TTCommons-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TT Commons";
  src: url("../public/fonts/TTCommons/TTCommons-Bold.ttf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
