.background-section {
  position: relative;
  width: 100%;
  height: 90vh;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.938),
      rgba(255, 255, 255, 0)
    ),
    url("../../../assets/Images/Home/Hero.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  margin-top: -60px;
}

.background-section .content {
  position: absolute;
  top: 45%;
  transform: translate(0, -45%);
  line-height: 1 !important;
}

.background-section .content p {
  line-height: 1;
}

.line-one {
  font-size: 60px;
  color: #ffff;
  font-weight: 500;
  font-family: "Termina", sans-serif;
}

.line-two {
  font-size: 60px;
  font-weight: 500;
  color: #ffff;
  font-family: "Termina", sans-serif;
}

.line-two span {
  font-family: "Termina", sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: #ffff;
}

.line-three {
  font-size: 60px;
  font-weight: 600;
  color: #ddfe34;
  font-family: "Termina", sans-serif;
}

@media (max-width: 767px) {
  .background-section {
    background-position: top;
    height: 30vh;
    margin-top: 0px;
  }

  .line-one {
    font-size: 20px !important;
    font-weight: 600;
    color: #ffff;
  }

  .line-two {
    font-size: 20px !important;
    font-weight: 600;
    color: #ffff;
  }

  .line-two span {
    font-size: 10px !important;
    font-weight: 600;
    color: #ffff;
  }

  .line-three {
    font-size: 20px !important;
    font-weight: 600;
    color: #ddfe34;
  }

  .background-section .content {
    position: absolute;
    top: 60% !important;
    transform: translate(0, -70%) !important;
    line-height: 1 !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .background-section {
    background-position: top;
    height: 45vh;
    margin-top: 0px;
  }

  .line-one {
    font-size: 40px;
  }

  .line-two {
    font-size: 40px;
  }

  .line-two span {
    font-size: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .background-section {
    margin-top: -120px;
    height: 60vh;
  }

  .background-section .content {
    position: absolute;
    top: 50% !important;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }
}

@media (min-width: 1600px) {
  .background-section {
    height: 70vh;
  }

  .background-section .content {
    position: absolute;
    top: 40% !important;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }
}
