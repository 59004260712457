.background-section-service {
  position: relative;
  width: 100%;
  height: 70vh;
  background-image: linear-gradient(
      to right,
      rgb(0, 0, 0),
      rgba(11, 8, 8, 0.501)
    ),
    url("../../../assets/Images/EarthworksQuantites/Hero.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  margin-top: -60px;
}

.background-section-service .content {
  position: absolute;
  top: 45%;
  transform: translate(0, -50%);
  line-height: 1 !important;
}

.background-section-service .content p {
  line-height: 1;
}

.line-three-service {
  font-family: "Termina", sans-serif;
  font-weight: 600;
  font-size: 60px;
  color: #ddfe34;
}

.price-check-btn {
  width: 180px;
  color: #000000;
  background: #ddfe34;
  padding: 20px;
  margin-top: 50px;
  text-align: center;
  font-family: "TT Commons", sans-serif;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

@media (max-width: 767px) {
  .background-section-service {
    background-position: top;
    height: 30vh;
    margin-top: 0px;
  }

  .line-three-service {
    font-size: 30px;
  }

  .background-section-service .content {
    position: absolute;
    top: 43% !important;
    transform: translate(0, -70%) !important;
    line-height: 1 !important;
  }

  .price-check-btn {
    width: 150px;
    padding: 10px;
    margin-top: 30px;
    text-align: center;
    font-weight: 500;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .background-section-service {
    background-position: top;
    height: 35vh;
    margin-top: 0px;
  }

  .background-section-service .content {
    position: absolute;
    top: 37% !important;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }

  .line-three-service {
    font-size: 30px;
  }

  .price-check-btn {
    margin-top: 40px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .background-section-service {
    margin-top: -120px;
    height: 50vh;
  }

  .background-section-service .content {
    position: absolute;
    top: 45% !important;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }
}

@media (min-width: 1600px) {
  .background-section-service {
    height: 50vh;
  }

  .background-section-service .content {
    position: absolute;
    top: 37% !important;
    transform: translate(0, -50%);
    line-height: 1 !important;
  }
}
